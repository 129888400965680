export const EXPLORE_PAGE_FILTERS = [
    { name: "price", label:"Price", type: "range", min: 0, max: 1000, step: 10, unit:"USDC",default:1000 },
    {
        name: "genres",
        label: "Genres",
        type: "multiselect",
        values: [
			{value: "Crime Fiction", label: "Crime Fiction"},
			{value: "Business", label: "Business"},
			{value: "Entrepreneurship", label: "Entrepreneurship"},
			{value: "Management", label: "Management"},
			{value: "Health", label: "Health"},
			{value: "Family", label: "Family"},
			{value: "Personal Development", label: "Personal Development"},
			{value: "Relationships", label: "Relationships"},
			{value: "Analysis & Strategy", label: "Analysis & Strategy"},
			{value: "Fantasy", label: "Fantasy"},
			{value: "Science Fiction", label: "Science Fiction"},
			{value: "Dystopian", label: "Dystopian"},
			{value: "Action & Adventure", label: "Action & Adventure"},
			{value: "Mystery", label: "Mystery"},
			{value: "Horror", label: "Horror"},
			{value: "Thriller & Suspense", label: "Thriller & Suspense"},
			{value: "Historical Fiction", label: "Historical Fiction"},
			{value: "Romance", label: "Romance"},
			{value: "Women's Fiction", label: "Women's Fiction"},
			{value: "LGBTQ+", label: "LGBTQ+"},
			{value: "Contemporary Fiction", label: "Contemporary Fiction"},
			{value: "Literary Fiction", label: "Literary Fiction"},
			{value: "Magical Realism", label: "Magical Realism"},
			{value: "Graphical Novel", label: "Graphical Novel"},
			{value: "Short Story", label: "Short Story"},
			{value: "Young Adult", label: "Young Adult"},
			{value: "New Adult", label: "New Adult"},
			{value: "Children's", label: "Children's"},
			{value: "Memoir & Autobiography", label: "Memoir & Autobiography"},
			{value: "Biography", label: "Biography"},
			{value: "Food & Drink", label: "Food & Drink"},
			{value: "Art & Photography", label: "Art & Photography"},
			{value: "Self-Help", label: "Self-Help"},
			{value: "History", label: "History"},
			{value: "Travel", label: "Travel"},
			{value: "True Crime", label: "True Crime"},
			{value: "Humor", label: "Humor"},
			{value: "Essays", label: "Essays"},
			{value: "Guide / How-To", label: "Guide / How-To"},
			{value: "Religion & Spirituality", label: "Religion & Spirituality"},
			{value: "Humanities & Social Sciences", label: "Humanities & Social Sciences"},
			{value: "Parenting & Families", label: "Parenting & Families"},
			{value: "Science & Technology", label: "Science & Technology"},
			{value: "Society & Culture", label: "Society & Culture"},
			{value: "Psychological Thrillers", label: "Psychological Thrillers"}
        ],
    },
	{
		name: "age_group",
		label: "Age Groups",
		type: "multiselect",
		values: [
			{value: "Children (0-14 years)", label: "Children (0-14 years)"},
			{value: "Youth (15-24 years)", label: "Youth (15-24 years)"},
			{value: "Adults (25-64 years)", label: "Adults (25-64 years)"},
			{value: "Seniors 65+", label: "Seniors 65+"},
			
		],
	},
    {
        name: "orderby",
        label: "Order By",
        type: "select",
        values: [
            {value:"PRICE_L_H",label:"Price - Low to High"},
            {value:"PRICE_H_L",label:"Price - High to Low"},
            {value:"RATING_L_H",label:"Rating - Low to Hight"},
            {value:"RATING_H_L",label:"Rating - High to Low"},
        ],
    }
]


export const ACCOUNT_PAGE_FILTERS = [
    { name: "price", label:"Price", type: "range", min: 0, max: 1000, step: 10, unit:"USDC" },
    { name: "decayscore", label:"Decay Score", type: "range", min: 0, max: 5, step: 0.01, unit:"" },
    {
        name: "genres",
        label: "Genres",
        type: "multiselect",
        values: [
            {value: "Crime Fiction", label: "Crime Fiction"},
			{value: "Business", label: "Business"},
			{value: "Entrepreneurship", label: "Entrepreneurship"},
			{value: "Management", label: "Management"},
			{value: "Health", label: "Health"},
			{value: "Family", label: "Family"},
			{value: "Personal Development", label: "Personal Development"},
			{value: "Relationships", label: "Relationships"},
			{value: "Analysis & Strategy", label: "Analysis & Strategy"},
			{value: "Fantasy", label: "Fantasy"},
			{value: "Science Fiction", label: "Science Fiction"},
			{value: "Dystopian", label: "Dystopian"},
			{value: "Action & Adventure", label: "Action & Adventure"},
			{value: "Mystery", label: "Mystery"},
			{value: "Horror", label: "Horror"},
			{value: "Thriller & Suspense", label: "Thriller & Suspense"},
			{value: "Historical Fiction", label: "Historical Fiction"},
			{value: "Romance", label: "Romance"},
			{value: "Women's Fiction", label: "Women's Fiction"},
			{value: "LGBTQ+", label: "LGBTQ+"},
			{value: "Contemporary Fiction", label: "Contemporary Fiction"},
			{value: "Literary Fiction", label: "Literary Fiction"},
			{value: "Magical Realism", label: "Magical Realism"},
			{value: "Graphical Novel", label: "Graphical Novel"},
			{value: "Short Story", label: "Short Story"},
			{value: "Young Adult", label: "Young Adult"},
			{value: "New Adult", label: "New Adult"},
			{value: "Children's", label: "Children's"},
			{value: "Memoir & Autobiography", label: "Memoir & Autobiography"},
			{value: "Biography", label: "Biography"},
			{value: "Food & Drink", label: "Food & Drink"},
			{value: "Art & Photography", label: "Art & Photography"},
			{value: "Self-Help", label: "Self-Help"},
			{value: "History", label: "History"},
			{value: "Travel", label: "Travel"},
			{value: "True Crime", label: "True Crime"},
			{value: "Humor", label: "Humor"},
			{value: "Essays", label: "Essays"},
			{value: "Guide / How-To", label: "Guide / How-To"},
			{value: "Religion & Spirituality", label: "Religion & Spirituality"},
			{value: "Humanities & Social Sciences", label: "Humanities & Social Sciences"},
			{value: "Parenting & Families", label: "Parenting & Families"},
			{value: "Science & Technology", label: "Science & Technology"},
			{value: "Society & Culture", label: "Society & Culture"}
        ],
    },
	{
        name: "age_group",
        label: "Age Groups",
        type: "multiselect",
        values: [
			{value: "Children (0-14 years)", label: "Children (0-14 years)"},
			{value: "Youth (15-24 years)", label: "Youth (15-24 years)"},
			{value: "Adults (25-64 years)", label: "Adults (25-64 years)"},
			{value: "Seniors 65+", label: "Seniors 65+"},
			
        ],
    },
    {
        name: "orderby",
        label: "Order By",
        type: "select",
        values: [
            {value:"PRICE_L_H",label:"Price - Low to High"},
            {value:"PRICE_H_L",label:"Price - High to Low"},
            {value:"RATING_L_H",label:"Rating - Low to Hight"},
            {value:"RATING_H_L",label:"Rating - High to Low"},
        ],
    }
]
