import React from 'react'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import './main.scss'

import Spinner from './components/ui/Spinner/Spinner'
import Snackbar from './components/ui/Snackbar/Snackbar'

import UserReducer from './store/reducers/user'
import ModalReducer from './store/reducers/modal'
import WalletReducer from './store/reducers/wallet'
import SpinnerReducer from './store/reducers/spinner'
import SnackbarReducer from './store/reducers/snackbar'
import DarkModeReducer from './store/reducers/darkmode'

import BookPage from './pages/Book'
import HomePage from './pages/Home'
import IndexPage from './pages/Index'
import ReaderPage from './pages/Reader'

import UserHOC from './components/hoc/User/UserHOC'
import WalletHOC from './components/hoc/Wallet/WalletHOC'
import ScrollToTop from './components/hoc/ScrollToTop/ScrollToTop'
import ProtectedRoute from './components/hoc/ProtectedRoute/ProtectedRoute'

const rootReducer = combineReducers({
	UserState: UserReducer,
	ModalState: ModalReducer,
	WalletState: WalletReducer,
	SpinnerState: SpinnerReducer,
	SnackbarState: SnackbarReducer,
	DarkModeState : DarkModeReducer
})

const store = createStore(rootReducer)

function App() {
	return (
		<div className="typo">
			<Provider store={store}>
				<Router>
					<ScrollToTop>
						<Routes>
							<Route path='/*' element={<IndexPage/>}/>
							<Route path='/' element={<IndexPage/>}/>
							<Route path='/book' element={<BookPage/>}/>
							<Route path='/home' element={<ProtectedRoute page={<HomePage/>}/>}/>
							<Route path='/book/preview' element={<ReaderPage/>}/>
							<Route path='/account/reader' element={<ReaderPage/>}/>
						</Routes>
					</ScrollToTop>
				</Router>
				<Snackbar/>
				<Spinner/>
				<UserHOC/>
				<WalletHOC/>
			</Provider>
		</div>
	)
}

export default App
