import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUser } from '../../../store/actions/user'
import { getData } from '../../../helpers/storage'
import { isUsable, isFilled } from '../../../helpers/functions'

import Constants from '../../../config/constants'

const UserHOC = props => {

	const dispatch = useDispatch()

	const UserState = useSelector(state => state.UserState)

	useEffect(() => {
		if(!isUsable(UserState.user) || !isFilled(UserState.user.uid) || !isFilled(UserState.token)){
			const userState = getData(Constants.USER_STATE)
			if(isUsable(userState) && isFilled(userState.user.uid) && isFilled(userState.token)){
				dispatch(setUser({...userState.user, token: userState.token}))
			}
		}
	}, [UserState, dispatch])

	return null
}

export default UserHOC