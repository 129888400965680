import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setWallet } from '../../../store/actions/wallet'
import Wallet from '../../../connections/wallet'
import { isUsable } from '../../../helpers/functions'

const WalletHOC = props => {

	const dispatch = useDispatch()

	const UserState = useSelector(state => state.UserState)

	useEffect(() => {
		if(Wallet.web3Modal.cachedProvider && isUsable(UserState.uid)){
			Wallet.connectWallet().then(res => {
				dispatch(setWallet({ wallet: res.wallet, provider: res.provider, signer: res.signer, address: res.address }))
			}).catch(err => {
				// navigate('/')
			})
		}
	}, [dispatch, UserState])

	return null
}

export default WalletHOC