import axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import React, { useEffect, useState } from 'react'

import Button from '../components/ui/Buttons/Button'
import InputField from '../components/ui/Input/Input'

import { hideSpinner, showSpinner } from '../store/actions/spinner'

import Banner from '../assets/logo/banner.png'
import GoogleIcon from '../assets/icons/google.png'

import { setUser } from '../store/actions/user'
import GaTracker from '../trackers/ga-tracker'

import { BASE_URL } from '../config/env'
import { setSnackbar } from '../store/actions/snackbar'

import Gradient1 from '../assets/images/mesh-gradient-1.png'
import Gradient2 from '../assets/images/mesh-gradient-2.png'
import Gradient3 from '../assets/images/mesh-gradient-3.png'
import Gradient4 from '../assets/images/mesh-gradient-4.png'
import { isUsable } from '../helpers/functions'

const IndexPage = props => {

	const GRADIENTS = [Gradient1, Gradient2, Gradient3, Gradient4]

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [Otp, setOtp] = useState("")
	const [Email, setEmail] = useState("")
	const [Loading, setLoading] = useState(false)
	const [UserInfo, setUserInfo] = useState(null)
	const [Password, setPassword] = useState("")
	const [GetPassword, setGetPassword] = useState(false)
	const [ConfirmationRequired, setConfirmationRequired] = useState(false)
	const [Gradient, setGradient] = useState(GRADIENTS[Math.floor(Math.random() * (3 - 0 + 1)) + 0])

	const loginhandler = () => {
		setPassword("")
		setLoading(true)
		axios({
			url: BASE_URL+'/api/admin/login',
			method: 'POST',
			data: {
				email: Email,
				pass: Password
			}
		}).then(res => {
			if(res.status === 200) {
				dispatch(hideSpinner())
				dispatch(setUser(res.data))
				navigate('/home')
			}
		}).catch(err => {
			dispatch(setSnackbar({show: true, message: "Incorrect username / password.", type: 3}))
		}).finally( () => setLoading(false) )
	}

	const emailHandler = () => {
		setLoading(true)
		axios({
			url: `${BASE_URL}/api/admin/info`,
			method: 'GET',
			params: {email: Email}
		}).then(res => {
			if(res.status === 200) setUserInfo(res.data)
		}).catch(err => {})
		.finally(() => {
			setLoading(false)
			setGetPassword(true)
		})
	}

	const renderContent = () => {
		if(GetPassword === false)
			return <React.Fragment>
				<p className='typo typo__head--4'>Log In</p>
				<p className='typo typo__subtitle typo__subtitle--2 utils__margin__bottom--s'>To continue to Admin Panel</p>
				<InputField type='string' label='email' value={Email} onChange={e => setEmail(e.target.value)}/>
				<Button className='utils__width--fill' size="xl" type="primary" onClick={() => emailHandler()}>Continue with email</Button>
			</React.Fragment>
		else return <React.Fragment>
			<p className='typo typo__head--4'>Welcome, {isUsable(UserInfo)?UserInfo.first_name:""}</p>
			<p className='typo typo__subtitle typo__subtitle--2 utils__margin__bottom--s'>Continue to Admin Panel</p>
			<InputField type='password' label='password' value={Password} onChange={e => setPassword(e.target.value)}/>
			<Button className='utils__width--fill' size="xl" type="primary" onClick={() => loginhandler()}>Log In</Button>
			{ConfirmationRequired?
				<React.Fragment>
					<div className='index__content__card__divider'>
						<div/>
						<p className='typo typo__body typo__body--2 utils__margin__bottom--n utils__margin__horizontal--m'>2FA</p>
						<div/>
					</div>
					<InputField type='number' label='Please enter the OTP' value={Otp} onChange={e => setOtp(e.target.value)}/>
					<Button className='utils__width--fill' size="xl" type="primary" onClick={() => navigate('/explore')}>Continue with email</Button>
					<p className='typo typo__cap'>Didn't get the OTP? <span className='typo__color--primary utils__cursor--pointer'>Resend</span></p>
				</React.Fragment>
			:null}
		</React.Fragment>
	}

	useEffect(() => { GaTracker('page_view_index') }, [])

	useEffect(() => {
		if(Loading) dispatch(showSpinner())
		else dispatch(hideSpinner())
	}, [dispatch, Loading])

	return (
		<div className='index'>
			<div className="index__hero">
				<img className="index__bg" src={Gradient} alt=''/>
				<div className="index__content">
					<img src={Banner} alt={'Nalnda'} className='index__content__banner'/>
					<div className='index__content__card'>
						{renderContent()}
					</div>
				</div>
			</div>
		</div>
	)
}

export default IndexPage
