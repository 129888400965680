import moment from "moment"
import jwt_decode from 'jwt-decode'
import { useNavigate } from "react-router"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Wallet from "../../../connections/wallet"

import { setWallet } from "../../../store/actions/wallet"
import { setSnackbar } from "../../../store/actions/snackbar"
import { setUser, unsetUser } from "../../../store/actions/user"
import { hideSpinner, showSpinner } from "../../../store/actions/spinner"

import { getData } from "../../../helpers/storage"
import { isFilled, isUsable } from "../../../helpers/functions"

import Constants from "../../../config/constants"

const ProtectedRoute = ({page}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const UserState = useSelector(state=>state.UserState)
	const WalletState = useSelector(state=>state.WalletState)

	const [isAuthenticated, setIsAuthenticated] = useState(false)

	useEffect(()=>{
		const userState = getData(Constants.USER_STATE)
		if(isUsable(UserState.user) && isFilled(UserState.user.uid) && isFilled(UserState.token)){
			const JWTexp = moment(jwt_decode(UserState.token).exp*1000)
			if(JWTexp.isAfter(moment())) {
				if(isUsable(WalletState.support) && WalletState.support === true && !isUsable(WalletState.wallet.provider)){
					Wallet.connectWallet().then(res => {
						dispatch(setWallet({ wallet: res.wallet, provider: res.provider, signer: res.signer, address: res.address }))
						dispatch(setSnackbar({show: true, message: "Wallet connected.", type: 1}))
						setIsAuthenticated(true)
					}).catch(err => {
						dispatch(setSnackbar({show: true, message: "Error while connecting to wallet", type: 4}))
					})
				}
				else setIsAuthenticated(true)
			}
			else {
				dispatch(unsetUser())
				dispatch(setSnackbar({show: true, message: "Session expired. Please login again.", type: 3}))
				navigate('/')
			}
		}
		else if(isUsable(userState) && isFilled(userState.user.uid) && isFilled(userState.token)){
			const JWTexp = moment(jwt_decode(userState.token).exp*1000)
			if(JWTexp.isAfter(moment())){
				dispatch(setUser({...userState.user, token: userState.token}))
				if(isUsable(WalletState.support) && WalletState.support === true && !isUsable(WalletState.wallet.provider)){
					Wallet.connectWallet().then(res => {
						dispatch(setWallet({ wallet: res.wallet, provider: res.provider, signer: res.signer, address: res.address }))
						dispatch(setSnackbar({show: true, message: "Wallet connected.", type: 1}))
						setIsAuthenticated(true)
					}).catch(err => {
						dispatch(setSnackbar({show: true, message: "Error while connecting to wallet", type: 4}))
					})
				}
				else setIsAuthenticated(true)
			}
			else {
				dispatch(unsetUser())
				dispatch(setSnackbar({show: true, message: "Session expired. Please login again.", type: 3}))
				navigate('/')
			}
		}
		else {
			dispatch(setSnackbar({show: true, message: "Please login again.", type: 2}))
			navigate('/')
		}
	},[UserState, dispatch, navigate])

	return <React.Fragment>
		{isAuthenticated?
			page
			:null
		}
	</React.Fragment>
}

export default ProtectedRoute
