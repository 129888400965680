import axios from 'axios'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import React, { useEffect, useState } from 'react'

import Page from '../components/hoc/Page/Page'

import Contracts from '../connections/contracts'

import Button from '../components/ui/Buttons/Button'

import Wallet from '../connections/wallet'

import { setWallet } from '../store/actions/wallet'
import { setSnackbar } from '../store/actions/snackbar'
import { hideSpinner, showSpinner } from '../store/actions/spinner'
import { isUsable } from '../helpers/functions'

import BackgroundBook from '../assets/images/background-book.svg'
import {ReactComponent as SynopsisIcon} from "../assets/icons/text.svg"
import {ReactComponent as USDCIcon} from "../assets/icons/usdc-icon.svg"
import {ReactComponent as ExternalLinkIcon} from "../assets/icons/external-link.svg"

import { BASE_URL } from '../config/env'

import GaTracker from '../trackers/ga-tracker'

const BookPage = props => {

	const TABS = [{id: 'TAB01', label: 'Synopsis', icon : <SynopsisIcon />}]

	const params = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const UserState = useSelector(state => state.UserState)
	const WalletState = useSelector(state => state.WalletState)

	const [WalletAddress, setWalletAddress] = useState(null)
	const [Loading, setLoading] = useState(false)
	const [ActiveTab, setActiveTab] = useState('TAB01')
	// NFT
	const [NFT, setNFT] = useState(null)
	const [Owner, setOwner] = useState(null)
	const [Listed, setListed] = useState(null)
	const [Published, setPublished] = useState(null)

	useEffect(() => { GaTracker('page_view_book') }, [])

	useEffect(() => {
		if(ActiveTab === 'TAB01') GaTracker('tab_view_book_synopsis')
	}, [ActiveTab])

	useEffect(() => { if(isUsable(NFT)) setListed(NFT.listed === 1?true:false) }, [NFT])

	useEffect(() => {
		setLoading(true)
		if(isUsable(WalletState.wallet.provider)) setWalletAddress(WalletState.wallet.address)
		setLoading(false)
	}, [WalletState])

	useEffect(() => {
		setNFT(params.state)
		if(isUsable(WalletAddress)){
			setLoading(true)
			const book = params.state
			if(book.new_owner === WalletAddress) setOwner(true)
			else setOwner(false)
			if(book.publisher_address === WalletAddress) setPublished(true)
			else setPublished(false)
		}
	}, [params, dispatch, WalletAddress])

	useEffect(() => { if(isUsable(NFT) && isUsable(Published) && isUsable(Owner)) setLoading(false) }, [NFT, Published, Owner])

	useEffect(() => {
		if(Loading) dispatch(showSpinner())
		else dispatch(hideSpinner())
	}, [Loading, dispatch])

	const readHandler = async () => {
		setLoading(true)
		try {
			axios({
				url: BASE_URL+'/api/admin/verify',
				method: 'GET',
				headers: {
					'user-id': UserState.user.uid,
					'Authorization': `Bearer ${UserState.token}`
				},
				params: {
					bookAddress: NFT.book_address,
					adminAddress: WalletAddress
				}
			}).then(res => {
				if(res.status === 200){
					const messageToSign = res.data
					Wallet.signMessage(WalletState.wallet.signer, JSON.stringify(messageToSign)).then(res => {
						if(res.isValid === true){
							axios({
								url : BASE_URL + '/api/admin/verify',
								method : "POST",
								headers: {
									'user-id': UserState.user.uid,
									'Authorization': `Bearer ${UserState.token}`
								},
								data : {
									adminAddress: WalletAddress,
									bookAddress: NFT.book_address,
									signedData: res.signedData,
									cid : NFT.book.slice(NFT.book.lastIndexOf("/")+1),
								}
							}).then(res=>{
								if(res.status === 200) {
									GaTracker('navigate_book_reader')
									navigate('/account/reader', {state: {book: {...NFT,submarineURL:res.data.url}, preview: false}}) 
								} else {
									dispatch(setSnackbar({show:true,message : "Error", type : 4}))
								}
							}).catch(err => {
							}).finally( () => setLoading(false))
						}
						else dispatch(setSnackbar({show: true, message: "Could not verify the authenticity of the signature.", type: 3}))
					})
				}
				else dispatch(setSnackbar('NOT200'))
			})
		} catch (err) {
			setLoading(false)
		}
	}

	const previewHandler = () => {
		GaTracker('navigate_book_preview')
		navigate('/book/preview', {state: {book: NFT, preview: true}})
	}

	const renderTabs = () => {
		let tabsDOM = []
		TABS.forEach(tab => {
			tabsDOM.push(
				<div onClick={()=>setActiveTab(tab.id)} className={tab.id === ActiveTab?"book__data__container__desc__tabs__container__item book__data__container__desc__tabs__container__item--active":"book__data__container__desc__tabs__container__item utils__cursor--pointer"} key={tab.id}>
					{isUsable(tab.icon) && tab.icon}
					<h5 className="typo__head typo__head--5">{tab.label}</h5>
				</div>
			)
		})
		return tabsDOM
	}

	const renderTabData = () => {
		switch (ActiveTab) {
			case 'TAB01':
				return <p className="typo__body typo__body--2 typo__color--n600">{NFT.synopsis}</p>
			default:
				break
		}
	}

	const onApproveHandler = () => {
		setLoading(true)
		Contracts.approveBooks([NFT.book_address], WalletState.wallet.signer).then(res => {
			axios({
				url: BASE_URL+'/api/admin/book/approve',
				method: 'GET',
				params: {bookAddress: NFT.book_address},
				headers: {
					'user-id': UserState.user.uid,
					'Authorization': `Bearer ${UserState.token}`
				}
			}).then(res => {
				if(res.status === 200){
					dispatch(setSnackbar({show: true, message: "Book Approved.", type: 1}))
					navigate('/home')
				}
				else dispatch(setSnackbar('NOT200'))
			}).catch(err => {
				dispatch(setSnackbar('ERROR'))
			}).finally(() => setLoading(false))
		}).catch(err => {
			if(isUsable(err.reason)){
				if(err.reason.includes("Already approved!")){
					axios({
						url: BASE_URL+'/api/admin/book/approve',
						method: 'GET',
						params: {bookAddress: NFT.book_address},
						headers: {
							'user-id': UserState.user.uid,
							'Authorization': `Bearer ${UserState.token}`
						}
					}).then(res => {
						if(res.status === 200){
							dispatch(setSnackbar({show: true, message: "Book Approved.", type: 1}))
							navigate('/home')
						}
						else dispatch(setSnackbar('NOT200'))
					}).catch(err => {
						dispatch(setSnackbar('ERROR'))
					}).finally(() => setLoading(false))
				}
				else if(err.reason.includes("Ownable: caller is not the owner")){
					setLoading(false)
					dispatch(setSnackbar({show: true, message: "You do not have permission to approve books.", type: 3}))
				}
				else {
					setLoading(false)
					dispatch(setSnackbar('ERROR'))
				}
			}
			else{
				setLoading(false)
				dispatch(setSnackbar('ERROR'))
			}
		})
	}

	const onRejectHandler = () => {
		setLoading(true)
		axios({
			url: BASE_URL+'/api/admin/book/reject',
			method: 'GET',
			params: {bookAddress: NFT.book_address},
			headers: {
				'user-id': UserState.user.uid,
				'Authorization': `Bearer ${UserState.token}`
			}
		}).then(res => {
			if(res.status === 200){
				dispatch(setSnackbar({show: true, message: "Book Rejected.", type: 1}))
				navigate('/home')
			}
			else dispatch(setSnackbar('NOT200'))
		}).catch(err => {
			dispatch(setSnackbar('ERROR'))
		}).finally(() => setLoading(false))
	}

	return (
		<Page>
			<div className="book__bg">
				<img src={BackgroundBook} alt="background"/>
			</div>
			{isUsable(NFT)?
				<React.Fragment>
					<div className="book__data">
						<div className="book__data__background"></div>
						<div className="book__data__container">
							<div>
								<div className='book__data__container__cover'>
									<img className='book__data__container__cover__image' src={NFT.cover_display_url?NFT.cover_display_url:NFT.cover_public_url?NFT.cover_public_url:NFT.cover} alt={NFT.name} />
								</div>
								<div className='book__data__container__meta'>
									<h3 className="typo__color--n700 typo__head typo__head--3 typo__transform--capital">{NFT.title}</h3>
									<h5 className="typo__color--n500 typo__head typo__head--5 typo__transform--upper">{NFT.author}</h5>
									<div className="book__data__container__meta__cta">
										<Button type="primary" size="lg" onClick={()=>readHandler()}>Read</Button>
										<Button onClick={()=>previewHandler()}>Preview</Button>
									</div>
								</div>
							</div>
							<div className='book__data__container__desc'>
								<div className="book__data__container__desc__left">
									<div className="book__data__container__desc__summary">
										<div className="book__data__container__desc__summary__contract">
											<div className='book__data__container__desc__summary__contract__data'>
												<div className='book__data__container__desc__summary__contract__label typo__color--n700'>Contract Address</div>
												<div className='book__data__container__desc__summary__contract__value typo__color--n700' onClick={()=>window.open(`https://mumbai.polygonscan.com/address/${NFT.book_address}`, "_blank")}>{(NFT.book_address||"").slice(0,4)}…{(NFT.book_address||"").slice((NFT.contract||"").length-4)}</div>
											</div>
											<div className='book__data__container__desc__summary__contract__icon'>
												<ExternalLinkIcon />
											</div>
										</div>
										<div className='book__data__container__desc__summary__head typo__color--n700'>Genres</div>
										<div className='book__data__container__desc__summary__chips typo__transform--capital'>{JSON.parse(NFT.genres).map(g=><div className="book__data__container__desc__summary__chips__item">{g}</div>)}</div>
										<div className='book__data__container__desc__summary__head typo__color--n700'>Prefered Age Group</div>
										<div className='book__data__container__desc__summary__data typo__transform--capital'>{JSON.parse(NFT.age_group).map(g=><div className="book__data__container__desc__summary__chips__item">{g}</div>)}</div>
										<div className='book__data__container__desc__summary__head typo__color--n700'>Language</div>
										<div className='book__data__container__desc__summary__data'>{NFT.language}</div>
										<div className='book__data__container__desc__summary__head typo__color--n700'>Price</div>
										<div className='book__data__container__desc__summary__data utils__d__flex utils__align__center'>{NFT.price}&nbsp;<USDCIcon width={24} height={24} fill='currentColor'/></div>
										<div className='book__data__container__desc__summary__head typo__color--n700'>Publication date</div>
										<div className='book__data__container__desc__summary__data'>{moment(NFT.publication_date).add(6, 'h').format("D MMM, YYYY")}</div>
									</div>
								</div>
								<div className="book__data__container__desc__right">
									<div className="book__data__container__desc__interacts">
									</div>
									<div className="book__data__container__desc__tabs">
										<div className="book__data__container__desc__tabs__container">
											{renderTabs()}
										</div>
										<div className="book__data__container__desc__tabs__data">
											{renderTabData()}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
				:null
			}
			<div className='book__actions'>
				<div onClick={()=>onApproveHandler()} className="book__actions__item typo__act">Approve</div>
				<div onClick={()=>onRejectHandler()} className="book__actions__item typo__act">Reject</div>
			</div>
		</Page>
	)
}

export default BookPage
